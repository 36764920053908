// For Login Actions
export const LOGIN_ACTION = 'LOGIN';
export const LOGOUT_ACTION = 'LOGOUT';
export const USER_ROLE ="USER_ROLE";

// For Bids Action
export const TEAMS_ADD_ACTION = 'TEAMS_ADD';
export const TEAMS_ADD_ACTION_API = 'TEAMS_ADD_API';
export const STAGES_ADD_ACTION = 'STAGES_ADD';
export const QUESTIONS_ADD_ACTION = 'QUESTIONS_ADD';
export const QUESTIONS_ADD_ACTION_API = 'QUESTIONS_ADD_API';
export const QUESTIONS_EDIT_ACTION = 'QUESTIONS_EDIT';
export const TEAMS_DELETE_ACTION = 'TEAMS_DELETE';
export const QUESTIONS_DELETE_ACTION = 'QUESTIONS_DELETE';
export const QUESTIONS_COLOR_ACTION = 'QUESTIONS_COLOR';
export const BID_CONTEXT_RESET_ACTION = 'BID_CONTEXT_RESET';

export const BID_ALL_QUESTIONS = 'BID_ALL_QUESTIONS';
export const BID_ALL_ADD_QUESTIONS = 'BID_ALL_ADD_QUESTIONS';
export const BID_ALL_DELETE_QUESTIONS = 'BID_ALL_DELETE_QUESTIONS';
export const BID_ALL_EDIT_QUESTIONS = 'BID_ALL_EDIT_QUESTIONS';
export const BID_ERROR = 'BID_ERROR';
